import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box, Drawer, Hidden, List, makeStyles
} from '@material-ui/core';
import {
  FcBriefcase,
  FcConferenceCall,
  FcList,
  FcSupport,
  FcSurvey,
  FcTodoList,
  FcDecision,
  FcContacts
} from 'react-icons/fc';
import NavItem from './NavItem';

const items = [
/*  {
    href: '/app/dashboard',
    icon: FcBarChart,
    title: 'Dashboard'
  },*/

  {
    href: '/app/users',
    icon: FcConferenceCall,
    title: 'Users'
  },
  {
    href: '/app/tasks',
    icon: FcBriefcase,
    title: 'Tasks'
  },
  {
    href: '/app/disputes',
    icon: FcContacts,
    title: 'Disputed'
  },
  {
    href: '/app/verifications',
    icon: FcDecision,
    title: 'Verifications'
  },
  {
    href: '/app/reports',
    icon: FcSurvey,
    title: 'Reports'
  },
  {
    href: '/app/support-articles',
    icon: FcTodoList,
    title: 'Support Articles'
  },
  {
    href: '/app/Grant-Admin',
    icon: FcList,
    title: 'Grant Admin'
  },
  {
    href: '/app/contact-us',
    icon: FcSupport,
    title: 'Contact us'
  },

  {
    href: '/app/blogs-list',
    icon: FcList,
    title: 'Blogs'
  }
   /*,
  {
    href: '/app/blogs-editor',
    icon: FcList,
    title: 'Blogs Editor'
  } 

  {
    href: '/app/support',
    icon: FcSupport,
    title: 'Support'
  },
  {
    href: '/app/logs',
    icon: FcList,
    title: 'Logs'
  } */
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 200,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
