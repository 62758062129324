import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { toast } from 'react-toastify';
import firebaseUtility, { privateUsers, auth } from "../../utils/firebase.utils";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import axios from 'axios';
import * as configs from '../../configs.json';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormWrapper from "../../views/task/edit task/components/input-fields/form-wrapper";
import FormInput from "../../views/task/edit task/components/input-fields/form-input";
import Text from "../../views/task/edit task/text";
const useStyles = makeStyles(() => ({
    root: {
        padding: '30px 20px',
    },
    divider: {
        height: '30px',
    },
}));


// eslint-disable-next-line react/prop-types
const ChargeUserTab = ({ user, privateData, closeDialog }) => {
    const classes = useStyles();
    const [chargeData, setChargeData] = useState({ amountInCents: "0", paymentMethod: "", description: "", userId: user.uid });
    const [userPaymentMethods, setUserPaymrntMethods] = useState([]);
    const [loading, setLoading] = useState(false);
    const [submitloading, setSubmitLoading] = useState(false);
    const[baseUrl,setBaseUrl]=useState('');

    useEffect(() => {
        if (process.env.REACT_APP_ENV === 'production') {
          setBaseUrl(configs.production.apiUrl);
        }else {
          setBaseUrl(configs.production.apiUrl);
        }
        console.log('charge:',baseUrl)
      }, [baseUrl])

    const getUserPyamentMethods = async () => {
        try {
            setLoading(true);
            let methods = [];
            const banks = await privateUsers.doc(user.uid).collection("connect_account").get();
            const cards = await privateUsers.doc(user.uid).collection("sources").get();
            for (let index = 0; index < banks.docs.length; index++) {
                const element = banks.docs[index].data();
                const account = { id: element.id, last4: element.external_accounts.data[0].last4, type: "Bank" }
                methods.push(account);
            }
            for (let index = 0; index < cards.docs.length; index++) {
                const element = cards.docs[index].data();
                const card = { id: element.id, last4: element.last4, type: "CreditCard" }
                methods.push(card);
            }
            setUserPaymrntMethods(methods);
            setChargeData({ userId: user.uid, paymentMethod: methods[0] && methods[0].id ? methods[0].id : "", amountInCents: "0", description: "" });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error('Somthing went wron loading user data');
        }
    }
    useEffect(() => {
        (async () => {
            await getUserPyamentMethods();
        })()
    }, [])

    const ChargeUserSubmit = () => {
        return new Promise(async (resolve, reject) => {
            setSubmitLoading(true);
            const body = {
                "userId": chargeData.userId,
                "paymentMethod": chargeData.paymentMethod,
                "amountInCents": chargeData.amountInCents,
                "description": chargeData.description
            };
            if (!chargeData.paymentMethod) {
                setSubmitLoading(false);
                toast.error('Please choose a vaild payment method');
                reject("need the payment method");
            } else if (!chargeData.description) {
                setSubmitLoading(false);
                toast.error('Please enter  description for the charge');
                reject("need the description");}
            // } else if (!chargeData.amountInCents||parseInt(chargeData.amountInCents)<0||parseInt(chargeData.amountInCents)!=Number) {
            //     setSubmitLoading(false);
            //     toast.error('Please enter a valid amount for the charge');
            //     reject("need the amount");
            // } 
            else {
                await auth
                    .currentUser.getIdToken(true)
                    .then(async token => {
                        await axios.post(
                            `${baseUrl}/chargeUser`,
                            body,
                            {
                                headers: {
                                    "content-type": "application/json",
                                    authorization: token
                                }
                            }
                        )
                            .then(result => {

                                toast.success('User charged successfully');
                                setSubmitLoading(false);
                                resolve("successfully");
                                closeDialog();
                            }).catch(err => {
                                console.log(err);
                                toast.error('Could not charge the user');
                                setSubmitLoading(false);
                                closeDialog();
                            });
                    })
                    .catch(err => {
                        console.log(err);
                        toast.error("Authentication failed. Please try again later.");
                        setSubmitLoading(false);
                        closeDialog();
                    });
            }
        });
    }

    return (
        <div className={classes.root}>
            {userPaymentMethods.length > 0 && !loading && <FormWrapper
                onSubmit={ChargeUserSubmit}
                initialValues={{
                    amount: chargeData.amountInCents, notes: chargeData.description
                }}
                style={{ position: "unset" }}
                onValueChange={(values => {
                    console.log("values :: ", values)
                    setChargeData({ userId: user.uid, paymentMethod: chargeData.paymentMethod, amountInCents: values.amount ? values.amount : "", description: values.notes ? values.notes : "" });

                })}
            >
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    spacing={2}
                >
                    <Grid item xs={6}>
                        <Typography variant="body2">
                            Chrage Amount In Cents 
                        </Typography>
                        <FormInput
                            autoComplete={"off"}
                            rootStyle={{ width: "100%" }}
                            name={"amount"}
                            placeholder={"Charge amount"}
                            // type={"number"}
                            required
                            // startAdornment={
                            //     <Text variant={"body2"} medium style={{ marginLeft: "16px" }}>
                            //         $
                            //     </Text>
                            // }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">
                            Payment Method
                        </Typography>
                        <FormControl variant="outlined" style={{ width: '100%', height: "100%" }}>
                            <Select
                                fullWidth
                                style={{ height: "45px", marginTop: "10px", paddingLeft: "10px" }}
                                labelId="status-label"
                                id="status-label"
                                value={chargeData.paymentMethod}
                                onChange={(ev) => {
                                    setChargeData({ userId: user.uid, paymentMethod: ev.target.value, amountInCents: chargeData.amountInCents, description: chargeData.description });
                                }}
                            >
                                {userPaymentMethods.map((account, index) => {
                                    if (account.type === "Bank") {
                                        return <MenuItem style={{ padding: "5px" }} key={"Method" + index} value={account.id}>{`Bank Account     ●●●●${account.last4}`}</MenuItem>;
                                    } else {
                                        return <MenuItem style={{ padding: "5px" }} key={"Method" + index} value={account.id}>{`Credit Card     ●●●●${account.last4}`}</MenuItem>;
                                    }
                                })}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            Charge Notes
                        </Typography>
                        <FormInput
                            name={"notes"}
                            placeholder={"Add detailed notes about the charge you want done"}
                            rowsMin={3} rowsMax={4} rows={3} multiline
                        />
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }} >
                        <Button
                            style={{ backgroundColor: "#f4f6f8", }}
                            disabled={submitloading}
                            autoFocus
                            type="submit"
                            color="primary"
                        >
                            {!submitloading ? "Submit Charge" : "Loading..."}
                        </Button>
                    </Grid>
                </Grid>
            </FormWrapper>}
            {userPaymentMethods.length <= 0 && !loading && <Typography
                align="center"
                color="textPrimary"
                variant="subtitle1"
            >
                Sorry there is no payment method's connected with this account
            </Typography>
            }
            {loading && <Typography
                align="center"
                color="textPrimary"
                variant="subtitle1"
            >
                Loading...
            </Typography>
            }
        </div >
    );
};

export default ChargeUserTab;
