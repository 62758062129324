import * as configs from './configs.json';

const exportConfigs = () => {

  const configsEnv = 'production';
  // const configsEnv = 'development';
  const configsJson = configs.default[configsEnv];
  return configsJson;
};

export default exportConfigs;
