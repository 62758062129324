import React , {useState , useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from '../../components/Page';
import exportConfigs from '../../configs';
import firebaseUtility from '../../utils/firebase.utils';
import { toast, ToastContainer } from 'react-toastify';
import * as configs from '../../configs.json';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const[baseUrl,setBaseUrl]=useState('');

  useEffect(() => {
      if (process.env.REACT_APP_ENV === 'production') {
        setBaseUrl(configs.production.apiUrl);
      }else {
        setBaseUrl(configs.production.apiUrl);
      }
      console.log('charge:',baseUrl)
    }, [baseUrl])

  const Login = async (values, setFieldError) => {
    const { email } = values;
    try {
      const isAdmin = await axios.post(`${exportConfigs().apiUrl}verifyAdmin`, {
        email
      });
      const result = await firebaseUtility
        .SignIn(values.email, values.password)
        .then(result => {
          console.log('logged in');
        })
        .catch(err => {
          throw err;
        });
    } catch (err) {
      if (err.code == 'auth/wrong-password') {
        toast.error('Incorrect password')
      } else {
        toast.error('You are not authorized');
      }    }
  };
  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup.string()
                .max(255)
                .required('Password is required')
            })}
            onSubmit={(values, { setFieldError }) => {
              return Login(values, setFieldError);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    Sign in
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {isSubmitting ? 'Logging in ...' : 'Sign in'}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
