import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Button,
} from '@material-ui/core';
import getInitials from '../../utils/getInitials';
import SkeletoLoader from '../../components/skeletoLoader';
import EditUserDialog from '../users/edit.user';
import { useNavigate, useParams } from 'react-router-dom';
import firebaseUtility from '../../utils/firebase.utils';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  rounded: {
    borderRadius: '15px',
  },
}));

// eslint-disable-next-line max-len
const Results = ({
  className,
  articles,
  isLoading,
  pageIndex,
  pageSizeIndex,
  setPageSize,
  pageChanged,
  ...rest
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const [limit, setLimit] = useState(pageSizeIndex);
  const [page, setPage] = useState(pageIndex);
  const [loading] = useState(isLoading);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPageSize(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage || page);
    pageChanged(newPage || page);
  };

  const getLoadingSkeleton = () => <SkeletoLoader page="Support"/>;

  const handleOpenArticle = (id) => {
    navigate(`/app/support-articles/edit/${id}`);
  };
  
  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                   <TableCell></TableCell>
                  <TableCell></TableCell> 
                </TableRow>
              </TableHead>
              {articles && articles.length > 0 ?
                <TableBody>
                  {!loading
                   ? articles.slice(0, limit).map((a) => (
                      <TableRow hover key={a.id} onClick={() => handleOpenArticle(a.id)}>
                        <TableCell>
                          <Box alignItems="center" display="flex">
                            <Typography color="textPrimary" variant="body1">
                              {a.title}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell/>
                        <TableCell/>
                        
                      </TableRow>
                    ))
                    : getLoadingSkeleton()}
                </TableBody>
                :
                <TableBody >
                  <TableCell colSpan={3}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                    <Typography variant={'body1'}> No Results Found! </Typography>
                  </div>
                  </TableCell>
                </TableBody>
              }
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={-1}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
      </Card>
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  articles: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setPageSize: PropTypes.func,
  pageChanged: PropTypes.func,
  pageSizeIndex: PropTypes.number,
  pageIndex: PropTypes.number,
};

export default Results;
